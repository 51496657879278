import { render, staticRenderFns } from "./HowtoWorkHome.vue?vue&type=template&id=50d3dffb&scoped=true"
import script from "./HowtoWorkHome.vue?vue&type=script&lang=js"
export * from "./HowtoWorkHome.vue?vue&type=script&lang=js"
import style0 from "./HowtoWorkHome.vue?vue&type=style&index=0&id=50d3dffb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d3dffb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
