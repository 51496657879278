
const actions = {
    // ============= Wallet Actions ===================
    set_wallet_info({commit}, value) {
        commit('SET_WALLET_INFO', value);
    },

    set_is_loading_wallet({commit}, value) {
        commit('SET_IS_LOADING_WALLET', value)
    }
}

export default actions
