<template>
  <v-container fluid style="padding: 0;">
    <header class="container-columns-navbar">
      <button class="button-nav" @click="(activo = !activo)">
        <img class="icon-menuburger" src="../../assets/menu-burger.svg" alt="Menu-Burger">
      </button>
      <router-link class="link-navigation" to="/"><img class="headerLogo" src="../../assets/logo-header.png" alt="LOGO">
      </router-link>
      <button class="btn-toolbar">
        <router-link class="link-navigation" to="/buytoken">Comprar token</router-link>
      </button>
      <img class="header-image-end" src="../../assets/image-header-end.png" alt="image-end-header">
      <nav class="nav" v-bind:class="{ activo }">
        <ul class="header-navigation">
          <li class="header-link">
            <router-link class="link-navigation" to="/">Home</router-link>
          </li>
          <li class="header-link">
            <router-link class="link-navigation" to="/token">Token</router-link>
          </li>
          <li class="header-link">
            <router-link class="link-navigation" to="/">Blog</router-link>
          </li>
          <li class="header-link">
            <router-link class="link-navigation" to="/">Hoja de Ruta</router-link>
          </li>
          <li class="header-link">
            <!-- AQUI EMPIEZA EL DROPDOWN -->
            <div class="dropdown-bar link-navigation" name="Recursos" @click="ActiveOptions()">
              Recursos
            </div>
            <div class="dropdown-list" id="form" v-if="flagActiveOptions" @click="ActiveOptions()">
              <div class="links-container-dropdown-menuhome">
                <router-link class="link-navigation" to="/about">Nosotros</router-link>
              </div>
              <a class="linkWhitepapper-menuhome" href="../../assets/pdf/Whitepaper-GirosYa.pdf" download="Whitepaper-GirosYa.pdf">
                <div class="links-container-dropdown-menuhome">
                  Whitepaper
                </div>
              </a>
              <div class="links-container-dropdown-menuhome">
                <router-link class="link-navigation" to="/questions">FAQ</router-link>
              </div>
            </div>
            <!-- AQUI TERMINA EL DROPDOWN -->
          </li>
        </ul>
      </nav>
    </header>
  </v-container>
</template>

<script>

export default {

  data() {
    // definición de variables y banderas
    return {
      flagActiveOptions: false,
      activo: false
    }
  },
  methods: {
    ActiveOptions() {
      this.flagActiveOptions = !this.flagActiveOptions;
    },
  },
};

</script>

<style lang="scss" scoped>
.container-columns-navbar {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0;
}

.button-nav {
  display: none;
}

.icon-menuburger {
  width: 2rem;
  height: 2rem;
}

.headerLogo {
  top: 0px;
  width: 10rem;
}

.header-image-end {
  width: 14rem;
}

.link-navigation {
  font-family: sans-serif;
  text-decoration: none;
  color: #333333;
}

.header-navigation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.header-link {
  font-weight: bold;
  list-style: none;
  text-decoration: none;
  margin-left: 4rem;
}

.linkWhitepapper-menuhome {
  text-decoration:none;
}

.btn-toolbar {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 1rem 1rem;
  width: 11rem;
  background: #ECCC37;
  border-radius: 65px;
}


.dropdown-bar {
  /* Dropdown */
  display: flex;
  cursor: pointer;
}

.dropdown-bar:hover .dropdown-list {
  opacity: 1;
  visibility: visible;
}

.dropdown-list {
  margin-left: -18px;
  margin-top: 5px;
  box-sizing: border-box;
  position: absolute;
  width: 119px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  z-index: 2;
}

.links-container-dropdown-menuhome {
  padding: 4px 8px 4px 8px;
  color: #000000;
}

.links-container-dropdown-menuhome:hover {
  background: #dfdfdf56;
  cursor: pointer;
}

@media screen and (max-width: 759px){

  .btn-toolbar {
    margin-top: -4rem;
    margin-left: 60%;

  }

  .header-image-end {
    display: none;
  }

  .button-nav {
    display: block;
    margin-left: 1rem;
    cursor: pointer;
  }

  .headerLogo {
    width: 5rem;
    margin-left: 100%;
  }

  .nav {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all .5s ease;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .nav.activo {
    pointer-events: auto;
    height: 50vh;
  }

  .header-navigation {
    flex-direction: column;
  }

  .header-link {
    margin: 1rem 0;
  }

}


@media screen and (min-width: 760px) and (max-width: 794px) {

  .btn-toolbar {
    margin-top: -4rem;
    margin-left: 60%;

  }

  .header-image-end {
    display: none;
  }

  .button-nav {
    display: block;
    margin-left: 1rem;
    cursor: pointer;
  }

  .headerLogo {
    width: 5rem;
    margin-left: 100%;
  }

  .nav {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all .5s ease;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .nav.activo {
    pointer-events: auto;
    height: 50vh;
  }

  .header-navigation {
    flex-direction: column;
  }

  .header-link {
    margin: 1rem 0;

  }

  .btn-toolbar {
    margin-top: 1rem;
  }

}

@media screen and (min-width: 795px) and (max-width: 1024px) {
  .container-columns-navbar {
    display: flex;
  }

  .headerLogo {
    width: 7rem;
  }

  .header-image-end {
    position: absolute;
    margin-left: 82%;
  }

  .btn-toolbar {
    width: 8rem;
    font-size: 12px;
    position: absolute;
    margin-left: 65%;
  }

  .header-link {
    font-size: 12px;
    margin-left: 2rem;
  }

  .nav {
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .nav {
    margin-left: 2rem;
  }

  .header-link {
    font-size: 14px;
    margin-left: 3rem;
  }

  .header-image-end {
    position: absolute;
    margin-left: 84%;
  }

  .btn-toolbar {
    width: 8rem;
    font-size: 12px;
    position: absolute;
    margin-left: 70%;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .nav {
    margin-left: 12rem;
  }

  .header-link {

    margin-left: 3rem;
  }

  .header-image-end {
    position: absolute;
    margin-left: 85%;
  }

  .btn-toolbar {
    width: 11rem;
    position: absolute;
    margin-left: 70%;
  }
}

@media screen and (min-width: 1441px) {
  .header-image-end {
    position: absolute;
    margin-left: 90%;
  }

  .header-link {
    margin-left: 3.5rem;
  }

  .btn-toolbar {
    width: 11rem;
    position: absolute;
    margin-left: 75%;
  }

  .header-navigation {
    margin-left: 20%;
    position: absolute;
    margin-top: -1rem;
  }
}

@media screen and (min-width: 1600px) {
  .header-image-end {
    position: absolute;
    margin-left: 90%;
  }

  .header-link {
    margin-left: 4rem;
  }

  .btn-toolbar {
    width: 11rem;
    position: absolute;
    margin-left: 78%;
  }

  .header-navigation {
    margin-left: 27%;
    position: absolute;
    margin-top: -1rem;
  }
}

@media screen and (min-width: 1800px) {
  .header-image-end {
    position: absolute;
    margin-left: 90%;
  }

  .header-link {
    margin-left: 5rem;
  }

  .btn-toolbar {
    width: 11rem;
    position: absolute;
    margin-left: 78%;
  }

  .header-navigation {
    margin-left: 28%;
    position: absolute;
    margin-top: -1rem;
  }
}
</style>