<template>
  <v-app>
    <v-main>
      <MenuHome></MenuHome>
      <router-view/>
      <FooterHome></FooterHome>
    </v-main>
  </v-app>
</template>

<script>
import MenuHome from "./components/menuhome/MenuHome.vue";
import FooterHome from "./components/footerhome/FooterHome.vue";

export default {
    name: "App",
    data: () => ({
    //
    }),
    components: { MenuHome, FooterHome },
};

</script>

<style>
body{
  overflow: hidden;
}
</style>
