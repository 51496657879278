<template>
  <home-girosya></home-girosya>
</template>

<script>
  import HomeGirosya from './HomeGirosya.vue'

  export default {
    name: 'Home',

    components: {
      
      HomeGirosya,
    },
  }
</script>
