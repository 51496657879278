
const mutations = {
// =================== Wallet Mutations ================================
    SET_WALLET_INFO(state, value) {
        state.wallet = value;
    },
    SET_IS_LOADING_WALLET(state, value) {
        state.isLoadingWallet = value;
    }
}

export default mutations
