<template>
  <v-container class="cardmenu-container" fluid>
    <v-row>
      <v-col class="columns-cardmenu">
        <router-link class="link-navigation" to="/Giros">
        <div class="elipse-cardmenu" @click="handleTabClick(tabNames.CARD1)"
          :class="{ 'elipse--active': activeTabName === tabNames.CARD1 }">
            <div style="width: 4rem; height: 4rem;">
              <img src="../../assets/iconGiros-card.png" alt="">
            </div>
        </div>
        </router-link>
        <div><span for="" class="label-cardmenu">Giros</span></div>
      </v-col>
      <v-col class="columns-cardmenu">
        <router-link class="link-navigation" to="/Corresponsal">
        <div class="elipse-cardmenu" @click="handleTabClick(tabNames.CARD2)"
          :class="{ 'elipse--active': activeTabName === tabNames.CARD2 }">
            <div style="width: 4rem; height: 4rem;">
              <img src="../../assets/iconCorresponsal-card.png" alt="">
            </div>
        </div>
        </router-link>
        <div>
          <span for="" class="label-cardmenu">Corresponsal<br><span style="margin-left: 20px;">
              bancario
            </span>
          </span>
        </div>
      </v-col>
      <v-col class="columns-cardmenu">
        <router-link class="link-navigation" to="/Recargas">
        <div class="elipse-cardmenu" @click="handleTabClick(tabNames.CARD3)"
          :class="{ 'elipse--active': activeTabName === tabNames.CARD3 }">
            <div style="width: 4rem; height: 4rem;">
              <img style="margin-left: 14px;" src="../../assets/iconRecargas-card.png" alt="">
            </div>
        </div>
        </router-link>
        <div><span for="" class="label-cardmenu">Recargas</span></div>
      </v-col>
      <v-col class="columns-cardmenu">
        <router-link class="link-navigation" to="/Envios">
        <div class="elipse-cardmenu" @click="handleTabClick(tabNames.CARD4)"
          :class="{ 'elipse--active': activeTabName === tabNames.CARD4 }">
            <div style="width: 4rem; height: 4rem;">
              <img src="../../assets/iconEnvios-card.png" alt="">
            </div>
        </div>
        </router-link>
        <div><span for="" class="label-cardmenu">Envíos</span></div>
      </v-col>
      <v-col class="columns-cardmenu">
        <router-link class="link-navigation" to="/SOAT">
        <div class="elipse-cardmenu" @click="handleTabClick(tabNames.CARD5)"
          :class="{ 'elipse--active': activeTabName === tabNames.CARD5 }">
            <div style="width: 4rem; height: 4rem;">
              <img src="../../assets/iconSoat-card.png" alt="">
            </div>
        </div>
        </router-link>
        <div><span for="" class="label-cardmenu">SOAT</span></div>
      </v-col>
      <v-col class="columns-cardmenu">
        <router-link class="link-navigation" to="/Seguros">
        <div class="elipse-cardmenu" @click="handleTabClick(tabNames.CARD6)"
          :class="{ 'elipse--active': activeTabName === tabNames.CARD6 }">
            <div style="width: 4rem; height: 4rem;">
              <img style="margin-left: 5px;" src="../../assets/iconSeguro-card.png" alt="">
            </div>
        </div>
        </router-link>
        <div><span for="" class="label-cardmenu" style="margin-left: 10px;">Seguros<br><span>exequiales</span></span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PagesCardsHomeGiros from "./pagescardmenuhome/PagesCardsHomeGiros.vue";
const tabNames = {
  CARD1: "card-1",
  CARD2: "card-2",
  CARD3: "card-3",
  CARD4: "card-4",
  CARD5: "card-5",
  CARD6: "card-6",
};
export default {
  data: () => ({
    currentTab: null,
    activeTabName: null,
    tabNames,
  }),
  methods: {
    handleTabClick: function (tabName) {
      this.activeTabName = tabName;
    },
  },
  components: { PagesCardsHomeGiros }
};
</script>

<style scoped>
.cardmenu-container {
  width: 90%;
  background: #213B8E;
  box-shadow: 0px 10px 100px #E5E3FF;
  border-radius: 20px;
  margin-top: -5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
}

.columns-cardmenu {
  padding-bottom: 3rem;
  height: 17rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.elipse-cardmenu {
  display: flex;
  align-items: center;
  top: 1623px;
  background: #FFFFFF;
  border-radius: 100%;
  padding: 2rem;
  margin-bottom: 24px;
}

.label-cardmenu {
  left: calc(50% - 54px/2 - 458px);
  top: 1750px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
}

.elipse--active {
  background: #ECCC37;
}

@media screen and (min-width: 320px) and (max-width: 608px) {
  .cardmenu-container {
    margin-top: 0;
    width: 100%;
    border-radius: 0px;
  }
}
</style>