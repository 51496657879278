<template>
  <v-container class="container-pagesHome" fluid>
    <v-row class="container-col-pageshome">
      <v-col>
        <span class="title-pages-home">
          Giros
        </span>
        <p class="text-pages-home">
          Es un servicio donde ofrecemos el envío de dinero representado en activos digitales en todo el territorio
          nacional, el cual puede ser reclamado por un destinatario en cualquiera de los puntos de atención físicos
          pertenecientes a la red de GirosYa o monetizarlo a través de la plataforma virtual.
        </p>
        <button class="button-pagesHome">
          Consulta con un asesor
        </button>
      </v-col>
      <v-col class="container-img-pageshome">
        <img class="image-pagesHome-giros" src="../../../assets/image-pages-home1.png" alt="">
        <img class="image-vector-pagesHome" src="../../../assets/Vector.png" alt="">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'pages-home-giros',
}
</script>

<style lang="scss" scoped>
.container-pagesHome {
  margin: 0;
  padding: 6rem;
}

.container-col-pageshome {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.container-text-cards-pageshome {
  display: grid;
  grid-template-columns: 1fr;
}

.title-pages-home {
  /* We Are Experts in El */
  width: 7rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */
  color: #213B8E;

  @media only screen and (min-width: 1024px) and (max-width: 1079px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1080px) and (max-width: 1280px) {
    font-size: 30px;
  }
}

.text-pages-home {
  width: 100%;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  color: #333333;
  margin-top: 32px;

  @media only screen and (min-width: 1024px) and (max-width: 1079px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1080px) and (max-width: 1280px) {
    font-size: 16px;
  }
}

.button-pagesHome {
  /* Rectangle */
  Width: 264px;
  Height: 36px;
  /* Yellow */
  background: #ECCC37;
  mix-blend-mode: normal;
  border-radius: 24px;
  /* FONT */
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* identical to box height, or 144% */
  text-align: center;
  /* Gray */
  color: #333333;
}

.image-pagesHome-giros {
  width: 90%;
}

.image-vector-pagesHome {
  margin-left: -100%;
  margin-bottom: -10%;
  width: 28%;
}

@media screen and (max-width: 479px) {
  .container-img-pageshome {
    display: none;
  }

  .container-pagesHome {
    margin: 0;
    padding: 3rem;
  }
}

@media screen and (max-width: 1023px) {
  .container-col-pageshome {
    grid-template-columns: 4fr;
  }

}

@media screen and (max-width: 720px) {
  .container-img-pageshome {
    display: none;
  }
}
</style>