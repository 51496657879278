
const state = {
    wallet: {
        isConnected: false,
        isMetamask: null,
        address: '',
        isBSC: null
    },

    isLoadingWallet: false
}

export default state
