import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/Giros',
        name: 'pages-home-giros',
        component: () => import('../components/cardmenuhome/pagescardmenuhome/PagesCardsHomeGiros')
      },
      {
        path: '/Corresponsal',
        name: 'pages-home-corresponsal',
        component: () => import('../components/cardmenuhome/pagescardmenuhome/PagesCardsHomeCorresponsal')
      },
      {
        path: '/Recargas',
        name: 'pages-home-recargas',
        component: () => import('../components/cardmenuhome/pagescardmenuhome/PagesCardsHomeRecargas')
      },
      {
        path: '/Envios',
        name: 'pages-home-envios',
        component: () => import('../components/cardmenuhome/pagescardmenuhome/PagesCardsHomeEnvios')
      },
      {
        path: '/SOAT',
        name: 'pages-home-soat',
        component: () => import('../components/cardmenuhome/pagescardmenuhome/PagesCardsHomeSOAT')
      },
      {
        path: '/Seguros',
        name: 'pages-home-seguros',
        component: () => import('../components/cardmenuhome/pagescardmenuhome/PagesCardsHomeSeguros')
      },
    ]
  },
  {
    path: '/about',
    name: 'about-us-girosya',
    component: () => import('../views/AboutUSGirosya.vue')
  },
  {
    path: '/blog',
    name: 'blog-girosya',
    component: () => import('../views/BlogGirosya.vue') 
  },
  {
    path: '/buytoken',
    name: 'buytoken-girosya',
    component: () => import('../views/BuyTokenGirosya.vue')
  },
  {
    path: '/questions',
    name: 'questions-girosya',
    component: () => import('../views/QuestionsGirosya.vue') 
  },
  {
    path: '/token',
    name: 'token-girosya',
    component: () => import('../views/TokenGirosya.vue') 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
