<template>
  <div>
    <GroupHome></GroupHome>
    <WeAreHome></WeAreHome>
    <div>
      <CardMenuHome></CardMenuHome>
    </div>
    <div class="container-info-pagescard">
      <Transition>
        <router-view />
      </Transition>
    </div>
    <HowtoWorkHome></HowtoWorkHome>
    <RouteSheetHome></RouteSheetHome>
    <ExpertsHome></ExpertsHome>
    <PartnersHome></PartnersHome>
  </div>
</template>
<script>
import GroupHome from "../components/grouphome/GroupHome.vue";
import WeAreHome from "../components/wearehome/WeAreHome.vue";
import HowtoWorkHome from "../components/howtoworkhome/HowtoWorkHome.vue";
import RouteSheetHome from "../components/routesheet/RouteSheetHome.vue";
import ExpertsHome from "../components/experts/ExpertsHome.vue";
import PartnersHome from "../components/partnershome/PartnersHome.vue";
import CardMenuHome from "@/components/cardmenuhome/CardMenuHome.vue";

export default {
  name: 'home-girosya',

  components: {
    GroupHome,
    WeAreHome,
    HowtoWorkHome,
    RouteSheetHome,
    ExpertsHome,
    PartnersHome,
    CardMenuHome
  },
}
</script>
<style lang="scss" scoped>
.container-info-pagescard {
  @media only screen and (min-width: 1024px) and (max-width: 1079px) {
    padding: 0px;
    margin-left: -60px;
  }

  @media only screen and (min-width: 1080px) and (max-width: 1200px) {
    padding: 0px;
    margin-left: -60px;
  }
}
</style>